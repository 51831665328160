import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout'
import './App.css'

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-ugandan-black font-arcade text-xs">
        <Routes>
          <Route path="/" element={<Layout />} />
        </Routes>
      </div>
    </Router>
  )
}

export default App