import { GiSlingshot } from "react-icons/gi"

export const siteConfig = {
    // Basic site info
    name: "Da Wae",
    title: "Da Wae",
    description: "Nevah stop clicking! We must find da wae!",

    // Add content section for reusable text
    content: {
        hero: {
            title: "Do You Know Da Wae?",
            subtitle: "Nevah stop clicking! We must find da wae!",
            buttonText: "Find Da Wae"
        },
        about: {
            title: "About Da Wae",
            description: "Nevah stop clicking! We must find da wae!"
        },
        wiki: {
            url: "https://weykipedia.fandom.com/wiki/Special:AllPages",
            buttonText: "Visit Da Wiki"
        },
        memes: {
            title: "Ugandan Gallery",
            description: "Ugandan memes"
        },
        footer: {
            copyright: "© 2024 Da Wae. All Ebola reserved.",
            description: "Nevah stop clicking! We must find da wae!"
        }
    },

    // Contract info
    contract: {
        description: "DO YOU KNOW $DAWAE?\nBruddas $DAWAE is da OmniWae\n\nJoin Uganda army Brudda and cum click and spit wit us\n\nYou must help us find DA QWEEN Bruddas",
        chains: [
            {
                chainId: "solana",
                name: "Solana",
                address: "DZSs9nHSr9BBunLNWd6PDstesJ4PBLMFVK1GbZ9urYNZ",
                icon: "/icons/solana-icon.svg"
            },
            {
                chainId: "base",
                name: "Base",
                address: "0x0ED151c9749d39C3CA8E537125fB4053E0c9b55f",
                icon: "/icons/base-icon.svg"
            },
            {
                chainId: "bsc",
                name: "Binance Smart Chain",
                address: "0xb42FC163f95332552A824020F15ED81EE7f0cE78",
                icon: "/icons/bsc-icon.svg"
            },
            {
                chainId: "sonic",
                name: "Sonic",
                address: "0x7A5C61047aD8fA4a743c75Eb46a01300c7c9dADA",
                icon: "/icons/sonic-icon.svg"
            },
            {
                chainId: "ethereum",
                name: "Ethereum",
                address: "0x2A897De60073E13c1f34f672033c1C1D08657FbB",
                icon: "/icons/ethereum-icon.svg"
            }
        ]
    },

    // Social links
    socials: {
        telegram: "https://t.me/dawaectoportal",
        telegram_channel: "https://t.me/dawae_official",
        twitter: "https://x.com/Dawae_Official",
        twitter_community: "https://x.com/i/communities/1847151894243725699",
        TikTok: "https://tiktok.com/@dawae_official",
        linktree: "https://linktr.ee/dawae_official",
    },

    // Trading links
    trading: {
        Jupiter: "https://jup.ag/swap/SOL-DZSs9nHSr9BBunLNWd6PDstesJ4PBLMFVK1GbZ9urYNZ",
        Chart: "https://www.dextools.io/app/en/solana/pair-explorer/q3ULfxUXUuqU8U2YrWC3ajRRJaURvdrHX1AHdDreCnn",
        BullX: "https://bullx.io/terminal?chainId=1399811149&address=DZSs9nHSr9BBunLNWd6PDstesJ4PBLMFVK1GbZ9urYNZ&r=BYMIJHPASX",
        Photon: "https://photon-sol.tinyastro.io/en/lp/q3ULfxUXUuqU8U2YrWC3ajRRJaURvdrHX1AHdDreCnn",
        SlingShot: "https://slingshot.finance/token/solana/DZSs9nHSr9BBunLNWd6PDstesJ4PBLMFVK1GbZ9urYNZ?code=DAWAE&c=803&t=d"
    },

    // Navigation links
    navigation: [
        { name: "About", href: "#about" },
        { name: "Buy now", href: "#buy" },
        { name: "Memes", href: "#memes" },
        { name: "Community", href: "#community" },
    ],

    // Assets paths
    assets: {
        logo: "/ugandan-knuckles.png",
        introVideo: {
            src: "/videos/ugandan-intro.MP4",
            type: "video/mp4",
            poster: "/images/intro-thumbnail.png"
        }
    }
}

const { socials, trading } = siteConfig
export const socialsLinks = [
    {
        title: "Telegram Channel",
        icon: "FaTelegram",
        url: socials.telegram_channel,
    },
    {
        title: "Twitter",
        icon: "FaTwitter",
        url: socials.twitter,
    },
    {
        title: "TikTok",
        icon: "tiktok",
        url: socials.TikTok,
    },
    {
        title: "Telegram Group Chat",
        icon: "FaUsers",
        url: socials.telegram,
    }
]


export const tradingLinks = [
    {
        title: "Buy Now",
        icon: "jupiter",
        url: trading.Jupiter,
    },
    {
        title: "Chart",
        icon: "dextools",
        url: trading.Chart,
    },
    {
        title: "BullX",
        icon: "bullx",
        url: trading.BullX,
    },
    /* {
        title: "Photon",
        icon: "photon",
        url: trading.Photon,
    } */,
    {
        title: "SlingShot",
        icon: "slingshotBlack",
        url: trading.SlingShot,
    }
]