import { useState } from 'react';
import { siteConfig } from '../config/site.config'

export default function ContractInfo() {
    // State to track the currently selected chain
    const [selectedChain, setSelectedChain] = useState(siteConfig.contract.chains[0]);
    // State to track dropdown open/closed status
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    // State to show copy feedback
    const [showCopied, setShowCopied] = useState(false);

    const contract = siteConfig.contract;
    const wikiUrl = siteConfig.content.wiki.url;

    // Function to handle copying address to clipboard
    const copyToClipboard = (address) => {
        navigator.clipboard.writeText(address)
            .then(() => {
                setShowCopied(true);
                setTimeout(() => setShowCopied(false), 2000); // Hide after 2 seconds
            })
            .catch(err => {
                console.error('Failed to copy address: ', err);
            });
    };

    // Function to toggle dropdown
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    // Function to select a chain
    const selectChain = (chain) => {
        setSelectedChain(chain);
        setIsDropdownOpen(false);
    };

    return (
        <section id="about" className="py-16 bg-ugandan-black text-ugandan-white">
            <div className="container mx-auto px-4">
                <div className="max-w-3xl mx-auto text-center font-arcade">
                    <h2 className="text-3xl font-bold mb-6">Contract Information</h2>
                    <div className="bg-ugandan-red bg-opacity-20 p-6 rounded-lg">
                        <p className="text-sm mb-4">Contract Address:</p>

                        {/* Dropdown for chain selection */}
                        <div className="relative mb-6 max-w-md mx-auto">
                            <button
                                onClick={toggleDropdown}
                                className="w-full flex items-center justify-between bg-ugandan-black px-4 py-3 rounded font-retro"
                                aria-haspopup="true"
                                aria-expanded={isDropdownOpen}
                            >
                                <div className="flex items-center">
                                    {selectedChain.icon && (
                                        <img
                                            src={selectedChain.icon}
                                            alt={`${selectedChain.name} icon`}
                                            className="w-6 h-6 mr-4"
                                        />
                                    )}
                                    <span className="text-[18px]">{selectedChain.name}</span>
                                </div>
                                <svg
                                    className={`w-4 h-4 transition-transform ${isDropdownOpen ? 'transform rotate-180' : ''}`}
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                </svg>
                            </button>

                            {/* Dropdown menu with increased font size and spacing */}
                            {isDropdownOpen && (
                                <div className="absolute z-10 w-full mt-1 bg-ugandan-black border border-ugandan-red rounded-md shadow-lg">
                                    <ul className="py-1 max-h-60 overflow-auto" role="menu" aria-orientation="vertical">
                                        {contract.chains.map((chain) => (
                                            <li key={chain.chainId}>
                                                <button
                                                    onClick={() => selectChain(chain)}
                                                    className={`w-full text-left px-4 py-2 flex items-center hover:bg-ugandan-red hover:bg-opacity-20 ${selectedChain.chainId === chain.chainId ? 'bg-ugandan-red bg-opacity-10' : ''
                                                        }`}
                                                    role="menuitem"
                                                >
                                                    {chain.icon && (
                                                        <img
                                                            src={chain.icon}
                                                            alt={`${chain.name} icon`}
                                                            className="w-5 h-5 mr-4"
                                                        />
                                                    )}
                                                    <span className="text-[12px]">
                                                        {chain.name}
                                                    </span>
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>

                        {/* Clickable contract address block - optimized for mobile with smaller font */}
                        <div
                            onClick={() => copyToClipboard(selectedChain.address)}
                            className="bg-ugandan-black p-4 rounded-lg mb-4 relative cursor-pointer hover:bg-opacity-80 transition-all duration-200 group"
                            title="Click to copy address"
                            aria-label={`Copy ${selectedChain.name} address`}
                        >
                            {/* Copy indicator that appears on hover - positioned at the top */}
                            <div className={`absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-full mb-2 
                                transition-opacity pointer-events-none
                                ${showCopied ? 'opacity-0' : 'opacity-0 group-hover:opacity-100'}`}>
                                <span className="bg-ugandan-black border border-ugandan-red px-2 py-1 rounded text-sm whitespace-nowrap">
                                    Click to copy
                                </span>
                                {/* Triangle pointer */}
                                <div className="w-2 h-2 bg-ugandan-black border-r border-b border-ugandan-red transform rotate-45 absolute left-1/2 -translate-x-1/2 -bottom-1"></div>
                            </div>

                            <div className="flex items-center justify-center">
                                <code className="text-center font-mono text-xs xs:text-[10px] sm:text-xs md:text-sm lg:text-base break-all px-1">
                                    {selectedChain.address}
                                </code>
                            </div>

                            {/* Subtle highlight effect on hover */}
                            <div className="absolute inset-0 bg-ugandan-red bg-opacity-0 group-hover:bg-opacity-10 rounded-lg transition-all duration-200"></div>

                            {/* Copy feedback message */}
                            {showCopied && (
                                <div className="absolute top-0 right-0 mt-2 mr-2 bg-green-500 text-white px-2 py-1 rounded text-sm">
                                    Copied!
                                </div>
                            )}
                        </div>

                        <p className="mt-6 text-gray-300">
                            {contract.description.split('\n').map((line, index) => (
                                <span key={index}>
                                    {line}
                                    <br />
                                </span>
                            ))}
                        </p>
                    </div>

                    {/* Wiki Link */}
                    <div className="mt-8">
                        <a
                            href={wikiUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-block bg-ugandan-red hover:bg-opacity-90 text-ugandan-white px-6 py-3 rounded-lg transition-all duration-300 text-sm"
                        >
                            {siteConfig.content.wiki.buttonText}
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}