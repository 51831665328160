import { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FaTelegram, FaTwitter, FaBars, FaTimes } from 'react-icons/fa'
import { siteConfig } from '../config/site.config'
import Icon from './Icon'


export default function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [scrolled, setScrolled] = useState(false)
    const location = useLocation()

    const handleNavClick = (e, href) => {
        e.preventDefault()
        const element = document.querySelector(href)
        if (element) {
            const headerOffset = 80
            const elementPosition = element.getBoundingClientRect().top
            const offsetPosition = elementPosition + window.scrollY - headerOffset

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            })
        }
        closeMenu()
    }

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen)
        document.body.style.overflow = !isMenuOpen ? 'hidden' : 'unset'
    }

    const closeMenu = () => {
        setIsMenuOpen(false)
        document.body.style.overflow = 'unset'
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 10) {
                setScrolled(true)
            } else {
                setScrolled(false)
            }
        }

        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const renderNavLinks = () => {
        return siteConfig.navigation.map((item) => {
            const isPageLink = item.href.startsWith('/')

            if (isPageLink) {
                return (
                    <li key={item.name}>
                        <Link
                            to={item.href}
                            className="text-ugandan-white hover:text-ugandan-red transition px-4 py-2 block"
                            onClick={closeMenu}
                        >
                            {item.name}
                        </Link>
                    </li>
                )
            } else {
                return (
                    <li key={item.name}>
                        <a
                            href={location.pathname === '/' ? item.href : `/${item.href}`}
                            className="text-ugandan-white hover:text-ugandan-red transition px-4 py-2 block"
                            onClick={closeMenu}
                        >
                            {item.name}
                        </a>
                    </li>
                )
            }
        })
    }

    return (
        <header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${scrolled ? 'bg-ugandan-black shadow-lg' : 'bg-transparent'}`}>
            <nav className="container mx-auto px-4 py-4">
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        <img src="/ugandan-knuckles.png" alt="Ugandan Knuckles" className="h-12 w-12" />
                        <span className="ml-6 text-xl font-bold text-ugandan-white text-center">{siteConfig.name}</span>
                    </div>

                    {/* Desktop Navigation */}
                    <div className="hidden md:flex items-center space-x-8">
                        <ul className="flex space-x-4">
                            {renderNavLinks()}
                        </ul>
                        <div className="flex space-x-4 items-center">
                            <a href={siteConfig.socials.linktree} target="_blank" rel="noopener noreferrer" className="w-8 h-8 flex items-center justify-center">
                                <Icon name="linktree" className="w-6 h-7 text-ugandan-white hover:text-ugandan-red transition" />
                            </a>
                            <a href={siteConfig.socials.twitter} target="_blank" rel="noopener noreferrer" className="w-8 h-8 flex items-center justify-center">
                                <FaTwitter className="w-7 h-7 text-ugandan-white hover:text-ugandan-red transition" />
                            </a>
                            <a href={siteConfig.socials.TikTok} target="_blank" rel="noopener noreferrer" className="w-8 h-8 flex items-center justify-center">
                                <Icon name="tiktokWhite" className="w-8 h-8 hover:text-ugandan-red transition" />
                            </a>
                            <a href={siteConfig.trading.SlingShot} target="_blank" rel="noopener noreferrer" className="w-8 h-8 flex items-center justify-center">
                                <Icon name="slingshot" className="w-8 h-8 hover:text-ugandan-red transition" />
                            </a>
                        </div>
                    </div>

                    {/* Mobile Menu Button */}
                    <button
                        className="md:hidden text-ugandan-white hover:text-ugandan-red transition"
                        onClick={toggleMenu}
                        aria-label="Toggle menu"
                    >
                        {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
                    </button>
                </div>
            </nav>

            {/* Mobile Menu */}
            {isMenuOpen && (
                <div className="fixed inset-0 bg-ugandan-black backdrop-blur-sm z-40 md:hidden pt-20">
                    {/* Add close button */}
                    <button
                        onClick={closeMenu}
                        className="absolute top-7 right-4 text-ugandan-white hover:text-ugandan-red transition"
                        aria-label="Close menu"
                    >
                        <FaTimes size={24} />
                    </button>
                    <div className="container mx-auto px-4 py-8">
                        <div className="flex flex-col space-y-6">
                            {renderNavLinks()}
                            <div className="flex justify-center space-x-8 pt-6">
                                <a
                                    href={siteConfig.socials.linktree}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={closeMenu}
                                >
                                    <Icon name="linktree" className="text-3xl w-7 h-7 text-ugandan-white hover:text-ugandan-red transition" />
                                </a>
                                <a
                                    href={siteConfig.socials.twitter}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={closeMenu}
                                >
                                    <FaTwitter className="text-3xl w-7 h-7 text-ugandan-white hover:text-ugandan-red transition" />
                                </a>
                                <a
                                    href={siteConfig.socials.TikTok}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={closeMenu}
                                    className="text-ugandan-white"
                                >
                                    <Icon name="tiktokWhite" className="text-3xl w-7 h-7 hover:text-ugandan-red transition" />
                                </a>
                                <a href={siteConfig.trading.SlingShot} target="_blank" rel="noopener noreferrer" onClick={closeMenu}>
                                    <Icon name="slingshot" className="text-3xl w-7 h-7 hover:text-ugandan-red transition" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </header>
    )
}